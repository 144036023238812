// @ts-nocheck
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { filterOutline } from "ionicons/icons";
import moment from "moment";
import React from "react";

const FilterOrders = ({
  setFilters,
  showFilterModal,
  filterModal,
  setStartDateFilter,
  cancelFilters,
  setEndDateFilter,
}) => {
  return (
    <>
      {/* FIlter fab btn */}
      <IonFab vertical="bottom" horizontal="start" slot="fixed">
        <IonFabButton color="primary">
          <IonIcon icon={filterOutline} />
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            className="fab-custom"
            color="warning"
            onClick={() =>
              setFilters(moment().format(), moment().add(1, "days").format())
            }
          >
            Day
          </IonFabButton>
          <IonFabButton
            color="warning"
            className="fab-custom"
            onClick={() =>
              setFilters(
                moment().startOf("week").format(),
                moment().endOf("week").format()
              )
            }
          >
            Week
          </IonFabButton>
          <IonFabButton
            onClick={showFilterModal}
            color="warning"
            className="fab-custom"
          >
            Filter
          </IonFabButton>
        </IonFabList>
      </IonFab>

      {/* filter Modal */}
      <IonModal isOpen={filterModal.visible}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Select Dates:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={cancelFilters}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem className="mt-5">
            <IonLabel>Start Date:</IonLabel>
            <IonDatetime
              displayFormat="YYYY-MM-DD"
              value={filterModal.data.start_date}
              onIonChange={(e) => {
                setStartDateFilter(e.detail.value!);
              }}
            />
          </IonItem>
          <IonItem className="mt-4">
            <IonLabel>End Date:</IonLabel>
            <IonDatetime
              displayFormat="YYYY-MM-DD"
              value={filterModal.data.end_date}
              onIonChange={(e) => setEndDateFilter(e.detail.value!)}
            />
          </IonItem>
          <IonButton
            className="mt-2"
            color="primary"
            expand="block"
            onClick={() => setFilters()}
          >
            Filter
          </IonButton>
          <IonButton
            className="mt-2"
            color="danger"
            expand="block"
            onClick={cancelFilters}
          >
            Cancel
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default FilterOrders;
