import React from "react";
import {
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
} from "@ionic/react";

import "./OrderForm.scss";
import _ from "lodash";
import { OrderSummary } from "../OrderSummary/OrderSummary";
import { IOrderProductItem } from "../../../models/OrderProductItem";
import { IOrder } from "../../../models/Order";
import { ProductRow } from "../ProductRow/ProductRow";
import { LoosePanel } from "../LoosePanel/LoosePanel";
import { ILoose } from "../../../models/Loose";

interface OrderFormProps {
  productList: Array<{
    column_number: number;
    categories: Array<IOrderProductItem>;
  }>;
  looses: ILoose[];
  order: IOrder;
}

export const OrderForm: React.FC<OrderFormProps> = ({
  productList,
  looses,
  order,
}) => {
  return (
    <IonGrid className="p1-0 w-100">
      <div className="order-form-container w-100">
        <IonRow className="order-form order-form-logged">
          {/* order product Form */}
          {_.map(productList, (col) => (
            <IonCol
              key={col.column_number}
              className={`col-${col.column_number}`}
              sizeXl="4"
              sizeMd="4"
              size="4"
            >
              {
                // iterate for each category
                _.map(
                  col.categories,
                  (category) => (
                    <table className="order-form-table" key={category.id}>
                      {/*<IonListHeader>*/}
                      {/*  <IonGrid>*/}
                      <thead>
                        <tr className="order-form-category">
                          <th className="order-form-category-name">
                            <h2>{category.title}</h2>
                          </th>
                          <th className="size">
                            <h2>S</h2>
                          </th>
                          <th className="size">
                            <h2>N</h2>
                          </th>
                          <th className="size">
                            <h2>M</h2>
                          </th>
                          <th className="size">
                            <h2>L</h2>
                          </th>
                        </tr>
                      </thead>
                      {/*</IonGrid>*/}
                      {/*</IonListHeader>*/}
                      {/*<IonGrid>*/}
                      <tbody>
                        {_.map(category.products, (product) => (
                          // <IonItem key={product.id}>
                          <ProductRow
                            key={product.id}
                            product={product}
                            order={order}
                            type="last"
                          />
                          // </IonItem>
                        ))}
                      </tbody>
                    </table>
                  )
                  // </IonList>
                )
              }

              {/* Looses */}
              {col.column_number == 3 && (
                <LoosePanel order={order} looses={looses} isLoggedForm={true} />
              )}
            </IonCol>
          ))}
        </IonRow>
      </div>

      {/* returns */}
      {order.returns.length > 0 && (
        <div>
          <div className="order-return-title">Returns:</div>
          <table className="order-return-table">
            <thead>
              <tr>
                <th>Code</th>
                <th>Size</th>
              </tr>
            </thead>
            <tbody>
              {_.map(order.returns, (returnItem, index) => (
                <tr key={index}>
                  <td>{returnItem.code}</td>
                  <td>{returnItem.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <OrderSummary order={order} summaryType="last" />

      <IonRow>
        <IonCol size="12">
          <IonItem>
            <IonLabel position="floating">Order Code</IonLabel>
            <IonInput
              type="text"
              value={order.abbreviation}
              disabled={true}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Recieved Cash</IonLabel>
            <IonInput
              type="number"
              value={order.received_payment}
              disabled={true}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Message:</IonLabel>
            <IonTextarea
              className="order-message"
              value={order.message}
              disabled={true}
            ></IonTextarea>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
