// @ts-nocheck
import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import { APIs } from "../../../../../services/apiService";

import "./AddCodeModal.scss";
import { toast } from "react-toastify";

export const AddCodeModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [code, setCode] = useState();

  const toggleModal = () => {
    props.toggleModal();
  };

  const onSave = () => {
    const loadRequestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        APIs["product-tasks"].store,
        { product_codes: [code] }
        // loadRequestOptions
      )
      .then((res) => {
        console.log(res);
        // if (res.data.status) {
        //   props.onLoadedData();
        //   toggleModal();
        // }
      })
      .catch((e) => {
        toast.success("Something went wrong!");
      });
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
      showBackdrop={true}
      cssClass={"load-modal"}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Add product code:</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form>
          <IonItem className="location-form-input">
            <IonLabel position="floating">Code:</IonLabel>
            <IonInput
              value={code}
              onIonChange={(e) => setCode(e.target.value)}
            />
          </IonItem>

          <IonButton
            className="location-form-save-btn"
            color="primary"
            expand="full"
            onClick={onSave}
          >
            Save
          </IonButton>
        </form>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"Please wait..."} />
      </IonContent>
    </IonModal>
  );
};
