import React, { useEffect, useState } from "react";
import {
  IonCardContent,
  IonCol,
  IonGrid,
  IonList,
  IonListHeader,
  IonRow,
  IonSearchbar,
} from "@ionic/react";
import classNames from "classnames";
import { Select } from "../../../Select/Select";
import { toast } from "react-toastify";
import axios from "axios";
import { WarehouseItem } from "../../WarehouseItem/WarehouseItem";
import { APIs } from "../../../../services/apiService";
import { AddCodeModal } from "../AddCodeModal/AddCodeModal";

const Load = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [displayLoadModal, setDisplayLoadModal] = useState(false);
  const [tabType, setTabType] = useState("load");
  const [searchedLocation, setSearchedLocation] = useState("");
  const [locations, setLocations] = useState<any[]>(props.locations ?? []);
  const [filteredLocations, setFilteredLocations] = useState<any[]>([]);
  const [selected, setSelected] = useState(false);
  const [isFree, setFree] = useState<any>(false);
  const [meta, setMeta] = useState<any>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);

  const toggleModal = () => {
    props.toggleModal();
  };

  const columns = [
    { columnsName: "aisle", label: "Aisle", type: "string" },
    { columnsName: "bay", label: "Bay", type: "string" },
    { columnsName: "level", label: "Level", type: "string" },
    { columnsName: "position", label: "Position", type: "string" },
    { columnsName: "active", label: "Active", type: "string" },
    { columnsName: "title", label: "Product Title", type: "obj" },
    { columnsName: "code", label: "Product Code", type: "obj" },
    { columnsName: "kind", label: "Type", type: "obj" },
    // { columnsName: "action", label: "Action", type: "action" },
  ];

  const renderValue = (row: any, col: any) => {
    if (col.type === "string") return row[col.columnsName];
    else if (row["sizes"]?.length > 0) {
      if (col.columnsName === "title") return row["sizes"][0].title;
      else if (col.columnsName === "code") return row["sizes"][0].code;
      else if (col.columnsName === "kind") return row["sizes"][0].kind;
    }
    return "-";
  };

  const clickHandler = (data: any, type: string) => {
    if (type === "load") {
      setSelected(data);
      setDisplayLoadModal(true);
    } else if (type === "unload" && data.sizes?.length > 0) {
      setLoading(true);
      axios
        .get(APIs["product-size"].searchByCode(data.sizes[0].code))
        .then((res) => {
          if (res.data.data?.length > 0)
            axios
              .delete(
                APIs["product-size"].unLoad(res.data.data[0].id, data.id),
                data
              )
              .then((res) => {
                setLoading(false);

                getLocations(0);
                // props.getInitialLocations();
                toast.success("loading has been successful!");
              })
              .catch((e) => setLoading(false));
        })
        .catch((e) => {
          setLoading(false);
          toast.success("something went wrong!");
        });
    }
  };

  const onLoadedData = () => {
    getLocations(0);
    toast.success("unloading has been successful!");
  };

  const getLocations = async (
    page: number,
    free?: number
    // isInfiniteScroll?: boolean
  ) => {
    // if (!isInfiniteScroll) setLoading(true);
    return await axios
      .get(APIs.locations.search, {
        params: {
          per_page: meta.per_page,
          page,
          search_key:
            searchedLocation.length > 0 ? searchedLocation : undefined,
          is_free: free ?? (isFree ? 1 : 0),
        },
      })
      .then((res) => {
        // if (!isInfiniteScroll) setLoading(false);
        if (searchedLocation?.length > 0) {
          setFilteredLocations((locations: any[]) => [
            ...locations,
            ...res.data.data,
          ]);
        } else {
          setLocations((locations: any[]) => [...locations, ...res.data.data]);
        }
        setMeta(res.data.meta!);
        if (res.data.meta?.to >= meta.total) setIsInfinityLoadingDisabled(true);
      })
      .catch((err) => {
        // if (!isInfiniteScroll) setLoading(false);

        toast.error(
          err.customErrorMessage ?? "error in getting locations list"
        );
      });
  };

  useEffect(() => {
    if (searchedLocation?.length > 0) {
      setLoading(true);
      setMeta((prevMeta: any) => ({
        ...prevMeta,
        current_page: 1,
      }));
      getLocations(meta.current_page);
    } else {
      setFilteredLocations([]);
    }
  }, [searchedLocation]);

  useEffect(() => {
    getLocations(meta.current_page);
  }, []);

  return (
    <>
      <IonRow className="ion-row-search">
        <IonCol className="ion-col">
          <IonSearchbar
            className="ion-searcher"
            value={searchedLocation}
            onIonChange={(e) => setSearchedLocation(e.detail.value!)}
            debounce={2500}
            placeholder="Search Something ..."
          />
        </IonCol>
        <IonCol className="select-col">
          <IonRow className="select-row">
            <Select className="custom-ion-select" />
            <Select className="custom-ion-select" />
          </IonRow>
        </IonCol>
      </IonRow>
      <IonCardContent className="ion-card-content">
        <IonList className={classNames("ion-list-wrapper", "ion-list-desktop")}>
          <IonListHeader className="ion-list-header">
            <IonGrid>
              <IonRow>
                <IonCol>Aisle</IonCol>
                <IonCol>Bay</IonCol>
                <IonCol>Level</IonCol>
                <IonCol>Position</IonCol>
              </IonRow>
            </IonGrid>
          </IonListHeader>
          {(filteredLocations?.length > 0 ? filteredLocations : locations)?.map(
            (location: any) => (
              <WarehouseItem
                key={location.id}
                aisle={location.aisle}
                bay={location.bay}
                level={location.level}
                position={location.position}
                active={location.active}
                onLoadHandler={() => clickHandler(location, "load")}
              />
            )
          )}
        </IonList>
        <IonList className={classNames("ion-list-wrapper", "ion-list-mobile")}>
          {(filteredLocations?.length > 0 ? filteredLocations : locations)?.map(
            (location: any) => (
              <WarehouseItem
                key={location.id}
                aisle={location.aisle}
                bay={location.bay}
                level={location.level}
                position={location.position}
                active={location.active}
                label={true}
                className={classNames(
                  "custom-warehouse-item",
                  "warehouse-item-mobile"
                )}
                onLoadHandler={() => clickHandler(location, "load")}
              />
            )
          )}
        </IonList>
      </IonCardContent>

      {displayLoadModal && (
        <AddCodeModal
          displayModal={displayLoadModal}
          toggleModal={() => setDisplayLoadModal(!displayLoadModal)}
          selected={selected}
          onLoadedData={onLoadedData}
        />
      )}
    </>
  );
};

Load.propTypes = {};

export default Load;
