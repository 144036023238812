import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonBadge,
  useIonViewWillEnter,
  IonLoading,
} from "@ionic/react";
import {
  cartOutline,
  fileTrayStackedOutline,
  medkitOutline,
  peopleOutline,
  storefrontOutline,
  ticketOutline,
  attachOutline,
  hammerOutline,
} from "ionicons/icons";

/**
 * import styles
 */
import "./Dashboard.scss";

/**
 * import assets
 */
import axios from "axios";
import { APIs } from "../../services/apiService";
import _ from "lodash";
import { UserTypes } from "../../models/User";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Actions } from "../../store/actionTypes";
import Layout from "../../components/Layout/Layout";

const getUserAccess = {
  [UserTypes.stockController]: ["products"],
  [UserTypes.saleRepresentative]: ["orders", "stores"],
  [UserTypes.picker]: ["orders"],
  [UserTypes.manager]: ["orders", "users", "stores", "categories", "products"],
  [UserTypes.admin]: ["orders", "users", "stores", "categories", "products"],
};

const getCardIcon: any = {
  orders: medkitOutline,
  users: peopleOutline,
  stores: storefrontOutline,
  categories: fileTrayStackedOutline,
  products: cartOutline,
};

const Dashboard: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userName: any = useSelector<any>((state) => state.auth?.user?.name);
  const userAvatar: any = useSelector<any>((state) => state.auth?.user?.avatar);
  const userType: any = useSelector<any>((state) => state.auth?.user?.type);
  const newTicketCount: any = useSelector<any>(
    (state) => state.ticket?.newTicketCount
  );
  const [dashboardImg, setDashboardImg] = useState<undefined | string>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const changeRoute = (route: string) => {
    history.push(route);
  };

  const getNewTicketCounts = () => {
    return axios.get(APIs.tickets.newTickets).then((res) => {
      dispatch({
        type: Actions.TICKET.SET_NEW_TICKET_COUNT,
        payload: res.data.data.count,
      });
    });
  };

  const getFirstLetterOfName = (txt: any): string => {
    if (txt) if (txt.length > 0) return txt.substr(0, 1).toUpperCase();

    return "-";
  };

  const uploadDashboardImg = (event: any) => {
    setLoading(true);
    const img = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      // @ts-ignore
      setDashboardImg(e.target.result);
    };
    reader.readAsDataURL(img);

    const formData = new FormData();
    formData.append("background", img);

    axios
      .post(APIs.settings.index, formData)
      .then(() => {
        toast.success("Upload was successful.");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error in uploading.");
        setLoading(false);
      });
  };

  const getDashboardImg = () => {
    axios
      .get(APIs.settings.background)
      .then((res) => {
        setDashboardImg(res.data?.url);
      })
      .catch((err) => console.error(err));
  };

  /**
   * component load
   */
  useIonViewWillEnter(() => {
    // wait until all loaded
    getDashboardImg();
    Promise.all([getNewTicketCounts()])
      .then(() => {})
      .catch((err) => {
        toast.error(
          err.customErrorMessage ?? "error in getting dashboard data"
        );
      });
  }, []);

  return (
    <Layout pageTitle="" containerClass="dashboard-page" backButtonRoute={null}>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <div className="dashboard-title">
                <h1 className="hello-container">
                  hello, <span className="hello__name">{userName}</span>
                </h1>
                <Link to="/tickets">
                  <span className="tickets-badge">
                    <IonIcon icon={ticketOutline} />
                    {newTicketCount > 0 && (
                      <IonBadge className="tickets-count" color="primary">
                        {newTicketCount}
                      </IonBadge>
                    )}
                  </span>
                </Link>
              </div>
            </IonCol>
            {userType &&
              getUserAccess[userType]?.map((item: any) => (
                <IonCol
                  sizeSm={
                    getUserAccess[userType]?.length > 2 && item !== "orders"
                      ? "6"
                      : "12"
                  }
                  sizeXs={
                    getUserAccess[userType]?.length > 2 && item !== "orders"
                      ? "6"
                      : "12"
                  }
                  sizeMd={
                    getUserAccess[userType]?.length > 2 && item !== "orders"
                      ? "3"
                      : "12"
                  }
                  key={item}
                >
                  <IonButton
                    className={`dashboard-btn ${item}`}
                    onClick={() => changeRoute(`/${item}`)}
                  >
                    <div className="dashboard-btn__container">
                      <IonIcon
                        className="dashboard-btn__icon"
                        icon={getCardIcon[item]}
                      />
                      {item}
                    </div>
                  </IonButton>
                </IonCol>
              ))}
          </IonRow>
          <IonRow>
            {userType && (
              <>
                <IonCol
                  sizeXs="12"
                  sizeMd="6"
                  onClick={(e) => {
                    e.stopPropagation();
                    if ([UserTypes.admin, UserTypes.manager].includes(userType))
                      document.getElementById("upload")?.click();
                  }}
                >
                  {dashboardImg ? (
                    <>
                      <img
                        src={dashboardImg}
                        style={{
                          width: "100%",
                          borderRadius: "16px",
                          objectFit: "contain",
                          maxHeight: "400px",
                        }}
                        alt="dashboard img"
                      />
                      <input
                        type="file"
                        hidden
                        onChange={uploadDashboardImg}
                        id="upload"
                      />
                    </>
                  ) : [UserTypes.admin, UserTypes.manager].includes(
                      userType
                    ) ? (
                    <IonButton className="dashboard-btn d-flex justify-content-center align-items-center upload-btn">
                      <span className="dashboard-btn__container">
                        <IonIcon
                          icon={attachOutline}
                          className="dashboard-btn__icon"
                        />
                        <span>Upload Image</span>
                        <input
                          type="file"
                          hidden
                          onChange={uploadDashboardImg}
                          id="upload"
                        />
                      </span>
                    </IonButton>
                  ) : null}
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6" onClick={(e) => {}}>
                  <div className="daily-production">
                    <div className="title">
                      <IonIcon icon={hammerOutline} /> Daily Production
                    </div>
                    <div>
                      {
                        "Freshly prepared and packed with care to give you the best quality of Nuts."
                      }
                    </div>
                    <div className="btn">
                      <IonButton onClick={() => changeRoute(`/products`)}>
                        {"Add Daily Product"}
                      </IonButton>
                    </div>
                  </div>
                </IonCol>
              </>
            )}
          </IonRow>
        </IonGrid>

        {/* loading bar */}
        <IonLoading isOpen={loading} message={"Please wait..."} />
      </IonContent>
    </Layout>
  );
};

export default Dashboard;
