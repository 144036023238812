import React from "react";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import LogoImg from "../../assets/img/web-logo.png";

const Layout = ({
  containerClass = "orders-page",
  pageTitle,
  backButtonRoute,
  headerButtons = [],
  children,
}: {
  containerClass?: string;
  pageTitle: string;
  backButtonRoute?: string | null;
  headerButtons?: Array<{
    title: string;
    icon: string;
    action: any;
  }>;
  children: JSX.Element;
}) => {
  return (
    <IonPage className={containerClass}>
      <IonHeader>
        <IonToolbar>
          {backButtonRoute ? (
            <IonButtons slot="start">
              <IonBackButton defaultHref={backButtonRoute} />
            </IonButtons>
          ) : (
            <IonButtons slot="start">
              <IonMenuButton menu="main" autoHide={false} />
            </IonButtons>
          )}

          {backButtonRoute ? (
            headerButtons.map((btn) => (
              <IonButtons slot="end" className="menu-container">
                <IonButton onClick={btn.action}>{btn.title}</IonButton>
              </IonButtons>
            ))
          ) : (
            <div className="header-avatar-container" slot="end">
              <IonAvatar className="avatar">
                <img src={LogoImg} className="logo-img" alt="roynut" />
              </IonAvatar>
            </div>
          )}
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      {children}
    </IonPage>
  );
};

export default Layout;
