import React from "react";
import "./ReturnPanel.scss";
import _ from "lodash";
import {
  IonButton,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { trashBin } from "ionicons/icons";

export const ReturnPanel: React.FC<any> = ({
  order,
  setOrderReturnCode,
  setOrderReturnCount,
  setOrderReturnType,
  deleteItemFromReturns,
  addItemToReturns,
}) => {
  return (
    <div className="return-table w-100">
      <table className="w-100">
        <thead>
          <tr>
            <th>Return Code</th>
            <th>Count</th>
            <th>Type</th>
            <th>X</th>
          </tr>
        </thead>
        <tbody>
          {_.map(order.returns, (returnItem, index) => (
            <tr>
              <td className="p-0" width="50%">
                <IonInput
                  // tabIndex={-1}
                  value={returnItem.code}
                  onIonChange={(e) => setOrderReturnCode(e, index)}
                ></IonInput>
              </td>
              <td className="p-0" width="35%">
                <IonInput
                  // tabIndex={-1}
                  value={returnItem.count}
                  onIonChange={(e) => setOrderReturnCount(e, index)}
                ></IonInput>
              </td>
              <td className="p-0" width="20%">
                <IonRadioGroup
                  className="radio-container"
                  value={returnItem.type}
                  onIonChange={(e) => setOrderReturnType(e, index)}
                >
                  <div className="radio-item">
                    <IonRadio value="bag"></IonRadio>
                    <IonLabel className="label">Bag</IonLabel>
                  </div>
                  <div className="radio-item">
                    <IonRadio value="box"></IonRadio>
                    <IonLabel className="label">Box</IonLabel>
                  </div>
                </IonRadioGroup>
              </td>
              <td className="p-0" width="15%">
                <IonButton
                  className="w-100 mt-3"
                  tabIndex={-1}
                  fill="clear"
                  onClick={() => deleteItemFromReturns(index)}
                >
                  <IonIcon icon={trashBin} />
                </IonButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="w-100 return-button">
        <IonButton
          tabIndex={-1}
          fill="clear"
          expand="full"
          onClick={addItemToReturns}
        >
          <span>+</span>
          Add RETURN
        </IonButton>
      </div>
    </div>
  );
};
