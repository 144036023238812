import {
  IonAlert,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonListHeader,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  useIonViewDidLeave,
} from "@ionic/react";
import axios from "axios";
import { addOutline } from "ionicons/icons";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { OrderItem } from "../../components/Order/OrderItem/OrderItem";
import { IOrderItem } from "../../models/Order";
import { UserTypes } from "../../models/User";
import { APIs } from "../../services/apiService";
import { deleteDraftOrderById, getAllDraftOrders } from "../../services/db";
import "./Orders.scss";
import { RestockModal } from "../../components/Order/RestockModal/RestockModal";
import Layout from "../../components/Layout/Layout";
import OrderListItem from "../../components/Order/OrderListItem/OrderListItem";
import FilterOrders from "../../components/Order/FilterOrders/FilterOrders";

interface IOrderList {
  type: any | "draft" | "saved";
  order: IOrderItem;
}

export const Orders: React.FC = () => {
  // @ts-ignore
  let { storeid } = useParams();
  const location = useLocation();
  const history = useHistory();

  const userType: any = useSelector<any>((state) => state.auth.user.type);
  const userId: any = useSelector<any>((state) => state.auth.user.id);
  const username: any = useSelector<any>((state) => state.auth.user.name);

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [displayRestockDialog, setDisplayRestockDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showRollbackAlert, setShowRollbackAlert] = useState(false);
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [search, setSearch] = useState<string>("");
  const [orders, setOrders] = useState<Array<IOrderList>>([]);
  const [orderToDelete, setOrderToDelete] = useState<
    { order: IOrderItem; type: "draft" | "saved" } | undefined
  >(undefined);
  const [orderToRollback, setOrderToRollback] = useState<
    { order: IOrderItem; type: "draft" | "saved" } | undefined
  >(undefined);
  const [orderToDuplicate, setOrderToDuplicate] = useState<
    { order: IOrderItem; type: "draft" | "saved" } | undefined
  >(undefined);
  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 50,
    nextPageUrl: "http://api.roynutfoods.com:8080/api/v1/orders?page=1",
  });
  const [filterModal, setFilterModal] = useState({
    visible: false,
    data: {
      start_date: undefined,
      end_date: undefined,
    },
  });

  const getOrders = async (
    page: number,
    start?: any,
    end?: any,
    searchParam?: string
  ) => {
    searchParam = searchParam ?? search;
    return await axios
      .get(!storeid ? APIs.order.index : APIs.stores.orders(storeid), {
        params: {
          per_page: meta.per_page,
          page,
          search_key: searchParam.length > 0 ? searchParam : undefined,
          from:
            start || filterModal.data.start_date
              ? moment(start || filterModal.data.start_date).format(
                  "YYYY-MM-DD"
                )
              : undefined,
          to:
            end || filterModal.data.end_date
              ? moment(end || filterModal.data.end_date).format("YYYY-MM-DD")
              : undefined,
        },
      })
      .then((res) => {
        const { data } = res;
        setMeta({
          current_page: res.data.data.current_page,
          per_page: res.data.data.per_page,
          nextPageUrl: res.data.data.next_page_url + "",
        });
        return {
          ...data,
          data: _.map(res.data.data.data, (o) => ({
            type: "saved",
            order: o,
          })),
          nextPageUrl: res.data.data.next_page_url,
          per_page: res.data.data.per_page,
          current_page: res.data.data.current_page,
        };
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting orders");
        throw err;
      });
  };

  const getDraftOrders = async () => {
    // if storeId exists don`t get drafts
    if (storeid) {
      return [];
    }

    return await getAllDraftOrders(userId)
      .then((data) => {
        return _.map(data, (o) => ({
          type: "draft",
          order: o.data,
        })).sort(
          // @ts-ignore
          (a, b) => new Date(b.order.start_at) - new Date(a.order.start_at)
        );
      })
      .catch((err) => {
        toast.error("error getting draft orders");
        throw err;
      });
  };

  const doRefresh = async (
    event?: any,
    start?: any,
    end?: any,
    search?: string
  ) => {
    setLoading(true);
    setOrders([]);
    setIsInfinityLoadingDisabled(false);

    // TODO : fix Duplicate codes and add search Key to draft orders
    try {
      const data = await getOrders(1, start, end, search);
      const draftOrders = await getDraftOrders();
      let allOrders: Array<IOrderList> = [];

      allOrders = _.chain(allOrders)
        .concat(draftOrders, data.data)
        .sort((o) => o.type)
        .value();

      setOrders(allOrders);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }

    if (event) event.target.complete();
  };

  const doFetch = async (event: any) => {
    if (!loading) {
      try {
        let data = await getOrders(meta.current_page + 1);

        setOrders(orders.concat(data.data!));
        const { current_page, per_page, nextPageUrl } = data;
        setMeta({
          current_page,
          per_page,
          nextPageUrl,
        });

        event.target.complete();
        if (!nextPageUrl) setIsInfinityLoadingDisabled(true);
      } catch (e) {
        event.target.complete();
      }
    }
  };

  const deleteOrder = async () => {
    setLoading(true);

    if (orderToDelete?.type === "draft") {
      const result = await deleteDraftOrderById(orderToDelete!.order.id);

      if (result.ok) {
        setOrders((prevState) => {
          const newState = _.clone(prevState);
          const orderIndex = newState.findIndex(
            (o) => o.order.id === orderToDelete!.order.id
          );
          newState.splice(orderIndex, 1);

          return newState;
        });

        toast.success("order deleted successfully");
      }
    } else {
      axios
        .delete(APIs.order.delete(orderToDelete?.order.id))
        .then(() => {
          setOrders((prevState) => {
            const newState = _.clone(prevState);
            const orderIndex = newState.findIndex(
              (o) => o.order.id === orderToDelete!.order.id
            );
            newState.splice(orderIndex, 1);

            return newState;
          });

          toast.success("order deleted successfully");
        })
        .catch((err) => {
          toast.error(err.customErrorMessage ?? "error in deleting data");
        });
    }

    setOrderToDelete(undefined);
    setLoading(false);
  };

  const showDeleteOrderPopOver = (
    order: IOrderItem,
    type: "draft" | "saved"
  ) => {
    if (userType === "Picker") toast.info("Permission Denied");
    else {
      setShowDeleteAlert(true);
      setOrderToDelete({
        order: order,
        type: type,
      });
      document.querySelector("ion-item-sliding")!.closeOpened();
    }
  };

  const showRollbackOrderPopOver = (
    order: IOrderItem,
    type: "draft" | "saved"
  ) => {
    if (userType === "Picker") toast.info("Permission Denied");
    else {
      setShowRollbackAlert(true);
      setOrderToRollback({
        order: order,
        type: type,
      });
      document.querySelector("ion-item-sliding")!.closeOpened();
    }
  };

  const showDuplicateOrderPopOver = (
    order: IOrderItem,
    type: "draft" | "saved"
  ) => {
    if (userType === "Picker") toast.info("Permission Denied");
    else {
      setShowDuplicateAlert(true);
      setOrderToDuplicate({
        order: order,
        type: type,
      });
      document.querySelector("ion-item-sliding")!.closeOpened();
    }
  };

  const rollbackOrder = async () => {
    axios
      .post(APIs.order.rollback(orderToRollback?.order.id!))
      .then(() => {
        setOrders((prevState) => {
          const newState = _.clone(prevState);
          const orderIndex = newState.findIndex(
            (o) => o.order.id === orderToRollback!.order.id
          );
          newState.splice(orderIndex, 1);

          return newState;
        });

        toast.success("order deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in deleting data");
      });
  };

  const duplicateStoreOrder = async () => {
    setLoading(true);
    let order: IOrderItem = orderToDuplicate!.order;
    const requestData = {
      params: {
        from_order: 1,
      },
    };
    const fetchedOrder = await axios
      .get(APIs.order.show(order.id), requestData)
      .then((res) => res.data.data);

    fetchedOrder.sizes = _.map(fetchedOrder.sizes, (size) => ({
      ...size,
      count: size.pivot.count,
    }));

    fetchedOrder.looses = _.map(fetchedOrder.looses, (loose) => ({
      ...loose,
      quantity: loose.pivot.quantity,
    }));

    const orderRequestData = new FormData();

    // @ts-ignore
    orderRequestData.append("store_id", fetchedOrder.store?.id);
    orderRequestData.append("received_payment", "0");
    orderRequestData.append("message", "");
    fetchedOrder.sizes.forEach((size: any, index: any) => {
      orderRequestData.append(`sizes[${index}][id]`, size.id);
      orderRequestData.append(`sizes[${index}][count]`, size.count);
    });
    fetchedOrder.looses.forEach((loose: any, index: any) => {
      orderRequestData.append(`looses[${index}][id]`, loose.id);
      orderRequestData.append(`looses[${index}][quantity]`, loose.quantity);
    });
    fetchedOrder.halves.forEach((half: any) => {
      orderRequestData.append(`halves[]`, half);
    });
    orderRequestData.append(
      "start_at",
      moment().utc().format("YYYY-MM-DD HH:mm:ss")
    );

    try {
      let orderResult: boolean;

      orderResult = await axios
        .post(APIs.order.store, orderRequestData)
        .then((res) => res.data.status);

      if (orderResult) {
        // delete draft order if it`s a draft order
        toast.success("Order successfully duplicated.");
        doRefresh();
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.customErrorMessage ?? "Order didn`t save.");
    }
  };

  const showFilterModal = () => {
    setFilterModal((prevState) => ({
      ...prevState,
      visible: true,
    }));
  };

  const cancelFilters = () => {
    setFilterModal((prevState) => ({
      visible: false,
      data: {
        start_date: undefined,
        end_date: undefined,
      },
    }));
  };

  const setStartDateFilter = (val: any) => {
    setFilterModal((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        start_date: val,
      },
    }));
  };

  const setEndDateFilter = (val: any) => {
    setFilterModal((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        end_date: val,
      },
    }));
  };

  const setFilters = (start?: any, end?: any) => {
    setFilterModal((prevState) => ({
      data: {
        start_date: start ?? prevState.data.start_date,
        end_date: end ?? prevState.data.end_date,
      },
      visible: false,
    }));

    doRefresh(undefined, start, end);
  };

  /**
   * hooks and lifeCycles
   */
  useEffect(() => {
    const getInitialOrders = async () => {
      try {
        setLoading(true);
        // get all draft orders and backend orders and merge them together
        const data = await getOrders(1);
        const draftOrders = await getDraftOrders();
        let allOrders: Array<IOrderList> = [];

        allOrders = _.chain(allOrders)
          .concat(draftOrders, data.data)
          .sort((o) => o.type)
          .value();

        setOrders(allOrders);

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (!loading && search?.length === 0) getInitialOrders();
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!loading && location.state?.refresh) {
      doRefresh();
      history.location.state = null;
    }
  }, [location.state]);

  useEffect(() => {
    enableAutoRefresh();

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  useIonViewDidLeave(() => {
    if (intervalId) clearInterval(intervalId);
  }, [intervalId]);

  useEffect(() => {
    const searchOrders = async () => {
      disableAutoRefresh();

      try {
        setLoading(true);
        setMeta((prevMeta) => ({
          ...prevMeta,
          current_page: 1,
          nextPageUrl: "http://api.roynutfoods.com:8080/api/v1/orders?page=1",
        }));

        const data = await getOrders(1);
        const draftOrders = await getDraftOrders();
        let allOrders: Array<IOrderList> = [];

        allOrders = _.chain(allOrders)
          .concat(draftOrders, data.data)
          .sort((o) => o.type)
          .value();

        setOrders(allOrders);

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (!loading && search?.length > 0) searchOrders();
  }, [search]);

  const enableAutoRefresh = useCallback(() => {
    if (!intervalId) {
      const refreshPage = setInterval(() => {
        if (!loading) doRefresh();
      }, 60000);
      setIntervalId(refreshPage);
    }
  }, [intervalId]);

  const disableAutoRefresh = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  return (
    <Layout
      pageTitle={"Orders"}
      containerClass="orders-page"
      backButtonRoute={"/dashboard"}
      headerButtons={[
        {
          title: "Restock",
          icon: "",
          action: () => setDisplayRestockDialog(true),
        },
      ]}
    >
      <IonContent style={{ overflow: "auto" }}>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div>
          <IonSearchbar
            value={search}
            onIonChange={(e) => {
              setSearch(e.detail.value!);
              if (e.detail.value?.length === 0) {
                enableAutoRefresh();
                doRefresh(undefined, undefined, undefined, "");
              }
            }}
            autocomplete="off"
            debounce={2500}
            class="search-component"
          />
        </div>
        {/* columnLables, tableActions, classList, children */}
        <IonList>
          <IonListHeader className="ion-list-header">
            <IonGrid>
              <IonRow>
                <IonCol style={{ minWidth: "250px" }}>Store Name</IonCol>
                <IonCol>Creator</IonCol>
                <IonCol>Date & Time</IonCol>
                <IonCol>Total</IonCol>
                <IonCol>S</IonCol>
                <IonCol>N</IonCol>
                <IonCol>M</IonCol>
                <IonCol>L</IonCol>
                <IonCol>Half</IonCol>
                <IonCol>Loose</IonCol>
              </IonRow>
            </IonGrid>
          </IonListHeader>

          {loading
            ? _.times(10, (i) => <OrderItem key={i} isLoader={true} />)
            : orders.map((order) => {
                return (
                  <OrderListItem
                    order={order}
                    showDeleteOrderPopOver={showDeleteOrderPopOver}
                    showDuplicateOrderPopOver={showDuplicateOrderPopOver}
                    showRollbackOrderPopOver={showRollbackOrderPopOver}
                  />
                );
              })}
        </IonList>

        {/* Inifinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        {/* Fab Button */}
        {[
          UserTypes.admin,
          UserTypes.manager,
          UserTypes.saleRepresentative,
        ].includes(userType) && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton color="danger" routerLink="/new-order">
              <IonIcon icon={addOutline} />
            </IonFabButton>
          </IonFab>
        )}

        <FilterOrders
          setFilters={setFilters}
          showFilterModal={showFilterModal}
          filterModal={filterModal}
          setStartDateFilter={setStartDateFilter}
          cancelFilters={cancelFilters}
          setEndDateFilter={setEndDateFilter}
        />
        {/* Loading */}
        {/*<IonLoading*/}
        {/*  isOpen={loading}*/}
        {/*  message={'Loading data...'}*/}
        {/*/>*/}

        {/* Restock Modal */}
        {displayRestockDialog && (
          <RestockModal
            displayModal={displayRestockDialog}
            toggleModal={() => setDisplayRestockDialog(!displayRestockDialog)}
          />
        )}

        {/* Delete alert */}
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the order?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteOrder();
              },
            },
          ]}
        />

        {/* Rollback alert */}
        <IonAlert
          isOpen={showRollbackAlert}
          onDidDismiss={() => setShowRollbackAlert(false)}
          header={"Rollback"}
          message={"Do you want to rollback the order?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Rollback",
              handler() {
                rollbackOrder();
              },
            },
          ]}
        />

        {/* Duplicate alert */}
        <IonAlert
          isOpen={showDuplicateAlert}
          onDidDismiss={() => setShowDuplicateAlert(false)}
          header={"Duplicate"}
          message={
            "Do you want to duplicate the order? (only sizes and looses and halves"
          }
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Duplicate",
              handler() {
                duplicateStoreOrder();
              },
            },
          ]}
        />
      </IonContent>
    </Layout>
  );
};
