import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPopover,
  IonRow,
  IonSkeletonText,
  IonToolbar,
} from "@ionic/react";
import "./WarehouseItem.scss";
import { Avatar } from "../../Avatar/Avatar";
import classNames from "classnames";
import { ellipsisVerticalOutline } from "ionicons/icons";

interface WarehouseItemProps {
  aisle: string;
  bay: string;
  level: string;
  position: string;
  active?: boolean;
  isLoader?: boolean;
  label?: boolean;
  className?: string;
  onEditHandler?: () => void;
  onDeleteHandler?: () => void;
  onLoadHandler?: () => void;
  onUnloadHandler?: () => void;
}

export const WarehouseItem: React.FC<WarehouseItemProps> = ({
  aisle,
  bay,
  level,
  position,
  active = true,
  isLoader,
  label = false,
  className = "",
  onEditHandler,
  onDeleteHandler,
  onLoadHandler,
  onUnloadHandler,
}) => {
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  if (isLoader) {
    return (
      <IonItem className="order-item">
        <IonSkeletonText
          animated
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <IonLabel className="order-item-info">
          <IonSkeletonText
            animated
            style={{ width: "40%", height: "16px", marginBottom: "5px" }}
          />
          <IonSkeletonText animated style={{ width: "100%", height: "12px" }} />
        </IonLabel>
      </IonItem>
    );
  }

  const onClickPopoverActionsHandler = (e: any, type: string) => {
    e.stopPropagation();

    if (type === "Delete") {
      if (onDeleteHandler instanceof Function) {
        onDeleteHandler();
      }
    } else {
      if (onEditHandler instanceof Function) {
        onEditHandler();
      }
    }

    setShowPopover({ open: false, event: undefined });
  };

  return (
    <IonItem className={`warehouse-item ${className}`}>
      <Avatar
        label={active ? "A" : "D"}
        className={classNames({
          avatar: !active,
          "avatar active": active,
        })}
      />
      <IonGrid>
        <IonRow className={label ? "ion-rows-has-label" : "ion-rows"}>
          <IonCol className={label ? "ion-col-has-label" : ""}>
            <span>{label && "Aisle"}</span>
            {aisle}
          </IonCol>
          <IonCol className={label ? "ion-col-has-label" : ""}>
            <span>{label && "Bay"}</span>
            {bay}
          </IonCol>
          <IonCol className={label ? "ion-col-has-label" : ""}>
            <span>{label && "Level"}</span>
            {level}
          </IonCol>
          <IonCol className={label ? "ion-col-has-label" : ""}>
            <span>{label && "Position"}</span>
            {position}
          </IonCol>
        </IonRow>
      </IonGrid>
      {(onEditHandler || onDeleteHandler) && (
        <>
          <IonPopover
            isOpen={showPopover.open}
            event={showPopover.event}
            onDidDismiss={() =>
              setShowPopover({ open: false, event: undefined })
            }
            showBackdrop={false}
          >
            <IonContent className="ion-content">
              <IonCol className="popover-actions-content">
                <IonRow>
                  <IonButton
                    onClick={(e) => onClickPopoverActionsHandler(e, "Delete")}
                  >
                    Delete
                  </IonButton>
                </IonRow>
                <IonRow>
                  <IonButton
                    onClick={(e) => onClickPopoverActionsHandler(e, "Edit")}
                  >
                    Edit
                  </IonButton>
                </IonRow>
              </IonCol>
            </IonContent>
          </IonPopover>
          <IonToolbar
            className="ion-more-toolbar"
            onClick={(e) =>
              setShowPopover({ open: true, event: e.nativeEvent })
            }
          >
            <IonRow>
              <IonIcon
                className="ion-circle-icon"
                icon={ellipsisVerticalOutline}
              ></IonIcon>
            </IonRow>
          </IonToolbar>
        </>
      )}
      {onLoadHandler && (
        <IonButton
          className={classNames(label ? "label-load-button" : "load-button")}
          fill={label ? "solid" : "clear"}
          onClick={onLoadHandler}
          size="small"
          color="primary"
        >
          Load
        </IonButton>
      )}
      {onUnloadHandler && (
        <IonButton
          className="unload-button"
          fill="solid"
          onClick={onUnloadHandler}
          size="small"
          color="primary"
        >
          Unload
        </IonButton>
      )}
    </IonItem>
  );
};
