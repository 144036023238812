// main
const hostURL = "https://api.roynutfoods.com/api";
// test
// const hostURL = "http://api.roynutfoods.com:8080/api";
// const hostURL = "/api";
// const hostURL = 'http://localhost:8000/api';

const makeURL = (API: string, version = "v1") => {
  return `${hostURL}/${version}/${API}`;
};

const APIs = {
  login: makeURL("login"),
  profile: {
    get: makeURL("profile"),
    update: makeURL("profile"),
  },
  users: {
    index: makeURL("users"),
    indexAll: makeURL("user/all"),
    store: makeURL("users"),
    show: makeURL("users/"),
    update: makeURL("users/"),
    delete: makeURL("users/"),
    sageSignIn: makeURL("user/sageSignIn"),
  },
  stores: {
    index: makeURL("stores"),
    indexAll: makeURL("stores/index/all"),
    store: makeURL("stores"),
    show: (storeId: string | number) => makeURL(`stores/${storeId}`),
    orders: (storeId: string | number) => makeURL(`stores/${storeId}/orders`),
    update: makeURL("stores/"),
    delete: makeURL("stores/"),
    dontShowMessageAgain: (storeId: string | number) =>
      makeURL(`stores/${storeId}/dont-show-again`),
    lastOrdersOfStore: (storeId: string | number) =>
      makeURL(`stores/${storeId}/last-orders`),
  },
  categories: {
    index: makeURL("categories"),
    indexAll: makeURL("categories/index/all"),
    store: makeURL("categories"),
    show: makeURL("categories/"),
    update: makeURL("categories/"),
    delete: makeURL("categories/"),
  },
  products: {
    index: makeURL("products"),
    store: makeURL("products"),
    show(productId: string | number) {
      return makeURL(`products/${productId}`);
    },
    update: makeURL("products/"),
    delete: makeURL("products/"),
    sizes: {
      index(productId: string | number) {
        return makeURL(`products/${productId}/sizes`);
      },
      store(productId: string | number) {
        return makeURL(`products/${productId}/sizes`);
      },
      show(productId: string | number) {
        return makeURL(`products/${productId}/sizes/`);
      },
      update(productId: string | number, sizeId: string | number) {
        return makeURL(`products/${productId}/sizes/${sizeId}`);
      },
      delete(productId: string | number, sizeId: string | number) {
        return makeURL(`products/${productId}/sizes/${sizeId}`);
      },
      prices: makeURL("products/sizes/prices"),
      logs(productId: string | number, sizeId: string | number) {
        return makeURL(`products/${productId}/sizes/${sizeId}/logs`);
      },
    },
    lastOrdersOfStore: (productId: string, storeId: string) =>
      makeURL(`stores/${storeId}/products/${productId}/orders`),
    productList: makeURL(`products`, "v2"),
    productId: (productId: string | number) =>
      makeURL(`products/${productId}`, "v2"),
    logs: (productId: string | number) => makeURL(`products/${productId}/logs`),
  },
  looses: {
    index: makeURL("looses"),
    show: (looseId: string | number) => makeURL(`looses/${looseId}`),
    store: makeURL("looses"),
    update: (looseId: string) => makeURL(`looses/${looseId}`),
    delete: (looseId: string) => makeURL(`looses/${looseId}`),
    lastOrdersOfStore: (looseId: string, storeId: string) =>
      makeURL(`stores/${storeId}/looses/${looseId}/orders`),
  },
  order: {
    productList: makeURL("orders/products/index-for-order"),
    looseList: makeURL("orders/looses/index-for-order"),
    index: makeURL("orders"),
    show: (orderId: string | number) => makeURL(`orders/${orderId}`),
    store: makeURL("orders"),
    update: (orderId: any) => makeURL(`orders/${orderId}`),
    delete: (orderId: any) => makeURL(`orders/${orderId}`),
    setStatus: (orderId: string | number) =>
      makeURL(`orders/${orderId}/set-status`),
    logs: (orderId: string | number) => makeURL(`orders/${orderId}/logs`),
    rollback: (orderId: string | number) =>
      makeURL(`orders/${orderId}/rollback`), //post
  },
  tickets: {
    index: makeURL("tickets"),
    show: (ticketId: string) => makeURL(`tickets/${ticketId}`),
    store: makeURL("tickets"),
    update: (ticketId: string) => makeURL(`tickets/${ticketId}`),
    delete: (ticketId: string) => makeURL(`tickets/${ticketId}`),
    comments: {
      index: (ticketId: string) => makeURL(`tickets/${ticketId}/comments`),
      store: (ticketId: string) => makeURL(`tickets/${ticketId}/comments`),
    },
    newTickets: makeURL("tickets/notifications/count"),
  },
  reports: {
    stores: makeURL("reports/stores"),
    returns: makeURL("reports/returns"),
    productsPrices: makeURL("products/sizes/prices"),
    productsPricesExport: makeURL("product-size-export/prices"),
    productsStockExport: makeURL("product-size-export/stock"),
  },
  settings: {
    index: makeURL("settings"),
    background: makeURL("settings/background"),
  },
  backup: {
    download: makeURL("backup/download"),
  },
  "daily-productions": {
    index: makeURL("daily-productions"),
    bulkCreate: makeURL("daily-productions/bulk-insert"),
    update: (productId: number) => makeURL(`daily-productions/${productId}`),
  },
  "product-size": {
    index: makeURL("product-sizes"),
    searchByCode: (code: number) =>
      makeURL(`product-sizes/search-by-code?code=${code}`),
    searchProduct: (productSize: number) =>
      makeURL(`product-sizes/${productSize}/locations`),
    load: (productSize: number) =>
      makeURL(`product-sizes/${productSize}/locations`),
    unLoad: (productSize: number, locationId: number) =>
      makeURL(`product-sizes/${productSize}/locations/${locationId}`),
  },
  locations: {
    search: makeURL("locations"), // get
    create: makeURL("locations"), // post
    update: (locationId: number) => makeURL(`locations/${locationId}`), //put
    delete: (locationId: number) => makeURL(`locations/${locationId}`), //delete
    show: (locationId: number) => makeURL(`locations/${locationId}`), //get
  },
  returns: {
    index: makeURL("returns"), // post
  },
  "product-tasks": {
    index: makeURL("location-product-tasks"),
    store: makeURL("location-product-tasks"),
  },
};

export { APIs };
