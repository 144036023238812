import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  IonList,
} from "@ionic/react";
import { IStore } from "../../../models/Store";

import axios from "axios";
import _ from "lodash";
import { APIs } from "../../../services/apiService";
import { StoreItem } from "../../Store/StoreItem/StoreItem";
import { toast } from "react-toastify";
import { history } from "../../../AppRouter";

interface OrderStoreSelectModalProps {
  open: boolean;
  selectStore: Function;
  hideModal: Function;
  needStores?: boolean;
  store?: IStore;
}

export const OrderStoreSelectModal: React.FC<OrderStoreSelectModalProps> = ({
  open,
  selectStore,
  hideModal,
  needStores = true,
  store,
}) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [stores, setStores] = useState<Array<IStore>>([]);

  const getStores = (query?: string) => {
    setLoading(true);
    setStores([]);

    const requestData = {
      params: {
        per_page: 15,
        page: 1,
        from_order: 1,
        search_key: query ? query : undefined,
      },
    };

    axios
      .get(APIs.stores.index, requestData)
      .then((res) => {
        setStores(res.data.data);
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in getting stores");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setStore = (store: IStore) => {
    selectStore(store);
    hideModal();
  };

  const closeDialog = () => {
    if (!store?.id) history.goBack();
    hideModal();
  };

  useEffect(() => {
    if (needStores) getStores();
  }, []);

  useEffect(() => {
    if (search.length > 2) getStores(search);
    else if (needStores) getStores();
  }, [search]);

  return (
    <IonModal
      cssClass="order-product-modal"
      isOpen={open}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Select Store:</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={closeDialog}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonSearchbar
          value={search}
          onIonChange={(e) => setSearch(e.detail.value!)}
          autocomplete="on"
          debounce={2500}
        />

        <IonList>
          {loading
            ? _.times(10, (i) => <StoreItem key={i} isLoader={true} />)
            : stores.map((store, index) => (
                <StoreItem
                  key={index}
                  name={store.name}
                  address={
                    store.address
                      ? JSON.parse(`${store.address}`)?.location
                      : ""
                  }
                  onClick={() => setStore(store)}
                />
              ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};
