import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRefresher,
  IonRefresherContent,
  IonAlert,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSearchbar,
} from "@ionic/react";
import { addOutline, pencilOutline, trashOutline } from "ionicons/icons";

import "./Categories.scss";

import { CategoryItem } from "../../components/Category/CategoryItem/CategoryItem";
import { CategoryAddModal } from "../../components/Category/CategoryAddModal/CategoryAddModal";
import { ICategory } from "../../models/Category";
import { IPaginationMeta } from "../../models/PaginationMeta";
import axios from "axios";
import _ from "lodash";
import { APIs } from "../../services/apiService";
import { toast } from "react-toastify";
import { CustomList } from "../../components/List/List";
import { UserTypes } from "../../models/User";

export const Categories: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedCategoryToDelete, setSelectedCategoryToDelete] =
    useState<ICategory | null>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const categoryAddModalRef: any = useRef();

  const getCategories = async (page: number) => {
    return await axios
      .get(APIs.categories.index, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: search.length > 2 ? search : undefined,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "Error in getting categories.");
        return {
          data: [],
          meta: {},
        };
      });
  };

  const addNewCategoryToList = (category: ICategory) => {
    // TODO : fix this code
    getInitialCategories();
    toast.success("Category has been saved.");
  };

  const updateCategoryInList = (category: ICategory) => {
    // TODO : fix this code
    getInitialCategories();
    toast.success("Category has been saved.");
  };

  const confirmDeleteCategory = (category: ICategory) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    setShowDeleteAlert(true);
    setSelectedCategoryToDelete(category);
  };

  const deleteCategory = () => {
    setLoading(true);

    axios
      .delete(APIs.categories.delete + selectedCategoryToDelete!.id)
      .then(() => {
        doRefresh();
        setSelectedCategoryToDelete(null);
        setShowDeleteAlert(false);
        setLoading(false);
        toast.success("category deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "Error in deleting category.");
        setLoading(false);
      });
  };

  const editCategory = (category: ICategory) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    categoryAddModalRef.current.showEditModal(category);
  };

  const doFetch = async (event?: any) => {
    let data = await getCategories(meta.current_page + 1);

    setCategories(categories.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (categories.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setCategories([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getCategories(1);
    setCategories(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    categoryAddModalRef.current.showModal();
  };

  const getInitialCategories = async () => {
    setLoading(true);

    let data = await getCategories(1);
    setCategories(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles
   */
  useEffect(() => {
    getInitialCategories();
  }, []);

  useEffect(() => {
    const searchCategories = async () => {
      setLoading(true);
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1,
      }));

      let data = await getCategories(meta.current_page);

      setCategories(data.data);
      setMeta(data.meta!);
      setLoading(false);
    };

    searchCategories();
  }, [search]);

  const columnList = [
    {
      coloumnName: "avatar",
      type: "avatar",
      label: "Avatar",
    },
    {
      coloumnName: "title",
      type: "string",
      label: "Name",
    },
    {
      coloumnName: "description",
      type: "string",
      label: "Description",
    },
    // {
    //   coloumnName: "updated_at",
    //   type: "date",
    //   label: "Last Update",
    // },
  ];

  const listActions = [
    {
      side: "start",
      options: [
        {
          title: "Edit",
          color: "secondary",
          actionType: "edit",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            editCategory(item);
          },
          icon: pencilOutline,
        },
      ],
    },
    {
      side: "end",
      options: [
        {
          title: "Delete",
          actionType: "delete",
          color: "danger",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            confirmDeleteCategory(item);
          },
          icon: trashOutline,
        },
      ],
    },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle>Categories</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonSearchbar
          value={search}
          onIonChange={(e) => setSearch(e.detail.value!)}
          autocomplete="on"
          debounce={2500}
        />

        <CustomList
          columnList={columnList}
          listActions={listActions}
          data={categories}
          loading={loading}
        />
        {/* <IonList>
          {loading
            ? _.times(10, (i) => <CategoryItem key={i} isLoader={true} />)
            : categories.map((cat, index) => (
                <IonItemSliding key={index}>
                  <CategoryItem
                    title={cat.title}
                    description={cat.description}
                  />
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="primary"
                      onClick={() => editCategory(cat)}
                    >
                      Edit
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItemOptions side="end">
                    <IonItemOption
                      color="danger"
                      onClick={() => confirmDeleteCategory(cat)}
                    >
                      Delete
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
        </IonList> */}

        {/* Infinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        {/* Fab Button */}
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton color="danger" onClick={showAddModal}>
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>

        {/* Add/Edit Modal */}
        <CategoryAddModal
          ref={categoryAddModalRef}
          onCategoryInsert={addNewCategoryToList}
          onCategoryUpdate={updateCategoryInList}
        />

        {/* Delete alert */}
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the category?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteCategory();
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};
