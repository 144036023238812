import React from "react";
import { IonCol, IonGrid, IonList, IonListHeader, IonRow } from "@ionic/react";
import "./List.scss";
import classNames from "classnames";
import { UserItem } from "../User/UserItem/UserItem";
import ListItem from "./ListItem/ListItem";
import _ from "lodash";

export interface ColumnListItemConfig {
  coloumnName: string;
  type: string;
  label: string;
}

export const CustomList = ({
  data,
  loading,
  columnList,
  listActions,
  classList,
}: {
  data: any;
  loading: any;
  columnList: Array<ColumnListItemConfig>;
  listActions: any;
  classList?: any;
}) => {
  return (
    <div>
      <IonList className={classNames("ion-list-wrapper", "ion-list-desktop")}>
        <IonListHeader className="ion-list-header">
          <IonGrid>
            <IonRow>
              {columnList.map((col: ColumnListItemConfig) => (
                <IonCol>{col.label}</IonCol>
              ))}
              {/* {listActions?.length > 0 && (
                <IonCol className="pop-over">actions</IonCol>
              )} */}
            </IonRow>
          </IonGrid>
        </IonListHeader>
        {loading
          ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
          : data?.map((item: any) => (
              <ListItem
                key={item.id}
                columnList={columnList}
                item={item}
                listActions={listActions}
              />
            ))}
      </IonList>

      <IonList className={classNames("ion-list-wrapper", "ion-list-mobile")}>
        {loading
          ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
          : data?.map((item: any) => (
              <ListItem
                key={item.id}
                columnList={columnList}
                item={item}
                listActions={listActions}
                isMobile={true}
              />
            ))}
      </IonList>
    </div>
  );
};
