import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { useParams } from "react-router";
import { IOrderProductItem } from "../../models/OrderProductItem";
import { IOrder, ORDER_STATUS } from "../../models/Order";
import _ from "lodash";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { OrderForm } from "../../components/Order/OrderForm/OrderForm";

import "../NewOrder/NewOrder.scss";
import { PickerDetailsForm } from "../../components/Order/PickerDetailsForm/PickerDetailsForm";
import { useSelector } from "react-redux";
import { UserTypes } from "../../models/User";
import { toast } from "react-toastify";
import { gridOutline, listOutline, attachOutline } from "ionicons/icons";
import moment from "moment";
import { ILoose } from "../../models/Loose";
import { PRODUCT_SIZES } from "../../models/ProductSize";
import { AttachmentModal } from "../../components/AttachmentModal/AttachmentModal";
import { history } from "../../AppRouter";

export const OrderDetails: React.FC = () => {
  //@ts-ignore
  let { id } = useParams();
  const userType: any = useSelector<any>((state) => state.auth.user.type);

  const [loading, setLoading] = useState(true);
  const [displayAttachment, setDisplayAttachment] = useState(false);
  const [looses, setLooses] = useState<Array<ILoose>>([]);
  const [productList, setProductList] = useState<
    Array<{
      column_number: number;
      categories: Array<IOrderProductItem>;
    }>
  >([]);
  const [allProductList, setAllProductList] = useState<Array<any>>([]);
  const [order, setOrder] = useState<IOrder>({
    id: "",
    store: {
      id: "",
      name: "",
      address: {},
      message: "",
    },
    sizes: [],
    halves: [],
    returns: [],
    looses: [],
    lastbalance: 0,
    status: 0,
    message: "",
    created_at: "",
    received_payment: 0,
    creator: { name: "" },
    attachment: undefined,
    abbreviation: "",
  });
  const [currentSlide, setCurrentSlide] = useState(0);

  const toggleAttachmentModal = () => setDisplayAttachment(!displayAttachment);

  const changeOrderStatus = () => {
    setLoading(true);

    const setOrderStatusRequestData = {
      status: ORDER_STATUS.complete,
    };

    axios
      .put(APIs.order.setStatus(id), setOrderStatusRequestData)
      .then(() => {
        toast.success("order completed.");
        setOrder((prevState) => ({
          ...prevState,
          status: ORDER_STATUS.complete,
        }));
        history.location.state = { refresh: true };
        history.goBack();
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error saving data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * this method will recieve all products from backend and transform data to 3 column data
   */
  const getProductList = async (): Promise<any> => {
    try {
      let data = await axios
        .get(APIs.order.productList)
        .then((res) => res.data.data);
      setAllProductList(data);
      data = _.chain(data)
        .groupBy("column_number")
        .map((val, key) => ({
          column_number: key,
          categories: val,
        }))
        .value();

      setProductList(data);
    } catch (e) {
      toast.error(e.customErrorMessage ?? "ERROR getting products");
    } finally {
      return Promise.resolve();
    }
  };

  const getAllLooses = async () => {
    try {
      const data = await axios
        .get(APIs.order.looseList)
        .then((res) => Promise.resolve(res.data.data));
      setLooses(data);
    } catch (e) {
      toast.error("error getting loose data");
    } finally {
      return Promise.resolve();
    }
  };

  const getOrder = async (): Promise<any> => {
    try {
      let data = await axios
        .get(APIs.order.show(id))
        .then((res) => res.data.data);
      setOrder(data);
    } catch (e) {
      toast.error(e.customErrorMessage ?? "ERROR getting products");
    } finally {
      return Promise.resolve();
    }
  };

  const saleRepView = async () => {
    setLoading(true);
    setCurrentSlide(1);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const pickerView = async () => {
    setLoading(true);
    setCurrentSlide(0);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  /**
   * effects and lifeCycles
   */
  useEffect(() => {
    setLoading(true);

    Promise.all([getOrder(), getProductList(), getAllLooses()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error loading data");
        setLoading(false);
      });
  }, [id]);

  const groupedSizes = _.chain(order.sizes).groupBy("type").value();
  const groupedSizesCounts = _.chain(groupedSizes)
    .map((orderSizes, type) => {
      orderSizes.forEach((orderSize) => {
        allProductList.forEach((category) => {
          let product = category.products.find(
            // @ts-ignore
            (product) => orderSize.product_id === product.id
          );
          if (product) {
            // @ts-ignore
            orderSize["categoryOrder"] = category?.order;
            // @ts-ignore
            orderSize["productOrder"] = product?.order;
            // @ts-ignore
            orderSize["columnNumber"] = category?.column_number;
          }
        });
      });

      orderSizes.sort(
        // @ts-ignore
        (a, b): any =>
          // @ts-ignore
          (a.columnNumber as number) - (b.columnNumber as number) ||
          // @ts-ignore
          (a.categoryOrder as number) - (b.categoryOrder as number) ||
          // @ts-ignore
          a.productOrder - b.productOrder
      );
      return {
        type: type,
        count: _.chain(orderSizes)
          .map("pivot.count")
          .reduce((sum, c) => sum + c)
          .value(),
      };
    })
    .value();

  const totalSmall =
    _.find(groupedSizesCounts, (size: any) => size.type === PRODUCT_SIZES.small)
      ?.count ?? 0;
  const totalMedium =
    _.find(
      groupedSizesCounts,
      (size: any) => size.type === PRODUCT_SIZES.medium
    )?.count ?? 0;
  const totalNormal =
    _.find(
      groupedSizesCounts,
      (size: any) => size.type === PRODUCT_SIZES.normal
    )?.count ?? 0;
  const totalLarge =
    _.find(groupedSizesCounts, (size: any) => size.type === PRODUCT_SIZES.large)
      ?.count ?? 0;
  const totalLoose = _.chain(order.looses).map("pivot.quantity").sum().value();
  // TODO : this is not real total! total will be readt from backend later.
  const totalHalf = order.halves?.length ?? 0;
  // const totalLoose = looses.length;
  const totalReturns = order.returns.length;

  let totalSizeCount = 0;
  // @ts-ignore
  _.each(groupedSizesCounts, (size) => {
    totalSizeCount += size.count;
  });

  const total = totalSizeCount + totalHalf;

  return (
    <IonPage className="order-details-page">
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/orders" />
          </IonButtons>
          <IonTitle>Order Details</IonTitle>
          <div className="header-avatar-container" slot="end">
            <IonButtons slot="start">
              {order.attachment && (
                <IonButton
                  className="menu-btn"
                  onClick={() => toggleAttachmentModal()}
                >
                  <IonIcon icon={attachOutline} />
                </IonButton>
              )}
            </IonButtons>
          </div>
        </IonToolbar>
        <IonGrid className="p-0 m-0 bg-white text-dark">
          <IonRow className="p-0 m-0">
            <IonCol className="p-0 m-0" size="12">
              <div className="d-flex justify-content-between align-items-center p-0 m-0">
                <>
                  {currentSlide === 1 && (
                    <>
                      <div className="d-flex flex-column">
                        <h4 className="m-0 mt-1 ml-1">
                          Order NO:{" "}
                          <span>{order.id !== "" ? order.id : "-"}</span>
                        </h4>
                        <p className="m-0 mt-1 ml-1 font-small">
                          Shop name:
                          <span>
                            {order.id !== "" ? order?.store?.name : "-"}
                          </span>
                        </p>
                      </div>
                      {!loading && (
                        <IonGrid className="mx-5">
                          <IonRow>
                            <IonCol
                              className="d-flex justify-content-center"
                              size="12"
                            >
                              <IonButton
                                className="details-change-view-btn d-flex justify-content-center align-items-center mx-2"
                                onClick={pickerView}
                                // @ts-ignore
                                disabled={currentSlide === 0}
                              >
                                <IonIcon size="lg" icon={listOutline} />
                              </IonButton>
                              <IonButton
                                className="details-change-view-btn d-flex justify-content-center align-items-center mx-2"
                                onClick={saleRepView}
                                disabled={currentSlide === 1}
                              >
                                <IonIcon size="lg" icon={gridOutline} />
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      )}
                    </>
                  )}
                </>
                {currentSlide === 1 && (
                  <div className="pr-1 font-small">
                    <div>
                      Last Balance: <span>£{order.lastbalance ?? 0}</span>
                    </div>
                    <>
                      <div>Date: {moment(order.created_at).format("lll")}</div>
                      <div>Creator: {order.creator?.name}</div>
                    </>
                  </div>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {currentSlide === 0 && (
          <>
            <table className="picker-table bg-white text-dark">
              <tr>
                <td width="40%">
                  <span className="picker-view-summary-title">Shop name: </span>
                  {order.store?.name}
                </td>
                <td width="35%">
                  <span className="picker-view-summary-title">Date: </span>
                  {moment(order.created_at).format("MMM D,YY HH:mm")}
                </td>
                <td width="15%">
                  <span className="picker-view-summary-title">Creator: </span>
                  {order.creator?.name}
                </td>
                <td width="10%" colSpan={3}>
                  {!loading && (
                    <div className="d-flex flex-row w-100 justify-content-center align-items-center">
                      <IonButton
                        className="details-change-view-btn d-flex justify-content-center align-items-center"
                        onClick={pickerView}
                        disabled={currentSlide === 0}
                      >
                        <IonIcon size="lg" icon={listOutline} />
                      </IonButton>
                      <IonButton
                        className="details-change-view-btn d-flex justify-content-center align-items-center"
                        onClick={saleRepView}
                        // @ts-ignore
                        disabled={currentSlide === 1}
                      >
                        <IonIcon size="lg" icon={gridOutline} />
                      </IonButton>
                    </div>
                  )}
                </td>
              </tr>
            </table>
            <table className="picker-table bg-white text-dark">
              <tr>
                <td width="14%">
                  T: {totalLoose > 0 ? `${total} + ${totalLoose}L` : total}
                </td>
                <td width="14%">S: {totalSmall}</td>
                <td width="14%">N: {totalNormal}</td>
                <td width="14%">M: {totalMedium}</td>
                <td width="14%">L: {totalLarge}</td>
                <td width="14%">H: {totalHalf}</td>
                <td width="16%">LS: {totalLoose}</td>
              </tr>
            </table>

            {/* order`s Message */}
            {order.message && order.message !== "" && (
              <table className="picker-table  bg-white text-dark">
                <tbody>
                  <tr>
                    <td width="100%">message: {order.message}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </>
        )}
      </IonHeader>
      <IonContent>
        {!loading && (
          <>
            {currentSlide === 0 ? (
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <PickerDetailsForm
                      allProductList={allProductList}
                      order={order}
                      productCounts={{
                        totalSmall,
                        totalMedium,
                        totalNormal,
                        totalLarge,
                        totalLoose,
                        totalHalf,
                        totalReturns,
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <OrderForm
                productList={productList}
                order={order}
                looses={looses}
              />
            )}
          </>
        )}

        {[
          UserTypes.admin,
          UserTypes.manager,
          UserTypes.picker,
          UserTypes.saleRepresentative,
        ].includes(userType) && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  color={
                    order.status == ORDER_STATUS.complete
                      ? "success"
                      : "primary"
                  }
                  expand="full"
                  onClick={changeOrderStatus}
                  disabled={order.status != ORDER_STATUS.pending}
                >
                  {order.status == ORDER_STATUS.complete
                    ? "completed"
                    : "complete"}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}

        {/* loading bar */}
        <IonLoading isOpen={loading} message={"Please wait..."} />

        {displayAttachment && (
          <AttachmentModal
            isOpen={displayAttachment}
            toggleModal={toggleAttachmentModal}
            src={order.attachment}
          />
        )}
      </IonContent>
    </IonPage>
  );
};
