// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import { addCircleOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { APIs } from "../../../services/apiService";
import { AddDailyProductModal } from "./AddDailyProductModal/AddDailyProductModal";
import { EditDailyProductModal } from "./EditDailyProductModal/EditDailyProductModal";
import { dailyProductColumns } from "../../../constants/dailyProduction";

import "./DailyProductModal.scss";
import { UserTypes } from "../../../models/User";

export const DailyProductModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [displayAddDialog, setDisplayAddDialog] = useState(false);
  const [displayEditDialog, setDisplayEditDialog] = useState(false);
  const [search, setSearch] = useState();
  const [dailyItems, setDailyItems] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 40,
  });
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const userType: any = useSelector<any>((state) => state.auth?.user?.type);

  const toggleModal = () => {
    props.toggleModal();
  };

  const getDailyList = async (page = meta.current_page, override = false) => {
    setLoading(true);
    axios
      .get(APIs["daily-productions"].index, {
        params: {
          per_page: meta.per_page,
          page,
          // search,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.data?.length > 0) {
          const { current_page, per_page, last_page } = res.data.meta;
          setMeta({
            current_page,
            per_page,
            last_page,
          });
          if (current_page === last_page) setIsInfinityLoadingDisabled(true);
          if (search || override) setDailyItems(res.data.data);
          else setDailyItems((preData) => [...preData, ...res.data.data]);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.customErrorMessage ?? "ERROR getting products");
      });
  };

  const doFetch = async (event: any) => {
    try {
      getDailyList(meta.current_page + 1);
      event.target.complete();
    } catch (e) {
      event.target.complete();
    }
  };

  useEffect(() => {
    getDailyList();
  }, [isOpen]);

  // useEffect(() => {
  //   getDailyList(1);
  // }, [search]);

  const renderValue = (row, col) => {
    if (col === "creator") {
      return row[col].name;
    } else if (col === "product_size_id") {
      return row["product_size"].code;
    }
    return row[col];
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      {/* Update Products Modal */}
      {displayAddDialog && (
        <AddDailyProductModal
          displayModal={displayAddDialog}
          toggleModal={() => setDisplayAddDialog(!displayAddDialog)}
          getDailyList={getDailyList}
        />
      )}

      {/* Update Products Modal */}
      {displayEditDialog && (
        <EditDailyProductModal
          displayModal={displayEditDialog}
          toggleModal={() => setDisplayEditDialog(!displayEditDialog)}
          selectedRow={selectedRow}
          getDailyList={getDailyList}
        />
      )}

      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Daily Production:</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="text-success font-weight-bold"
              onClick={() => setDisplayAddDialog(true)}
            >
              <IonIcon icon={addCircleOutline} size="large" />
            </IonButton>
            <IonButtons slot="end">
              <IonButton onClick={toggleModal}>Close</IonButton>
            </IonButtons>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRefresher slot="fixed" onIonRefresh={getDailyList}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {/* <IonSearchbar
          className="px-0 mb-2"
          value={search}
          onIonChange={(e) => setSearch(e.detail.value!)}
          debounce={1000}
        /> */}

        <div style={{ overflowX: "auto" }}>
          <table className="product-update-table">
            <thead>
              <tr className="product-update-form-size-title">
                <th>
                  <h2>Name</h2>
                </th>
                {dailyProductColumns.map((col) => (
                  <th>
                    <h2>{col.label}</h2>
                  </th>
                ))}
                <th>
                  <h2>Creator</h2>
                </th>
                {[UserTypes.admin, UserTypes.manager].includes(userType) && (
                  <th>
                    <h2>Action</h2>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {dailyItems?.map((row: any, index) => (
                <tr key={"row" + index}>
                  <td>{row["product_size"].title}</td>
                  {[...dailyProductColumns, { columnName: "creator" }].map(
                    (col, i) => {
                      return (
                        <td key={"row" + index + "col" + i}>
                          {renderValue(row, col.columnName)}
                        </td>
                      );
                    }
                  )}
                  {[UserTypes.admin, UserTypes.manager].includes(userType) && (
                    <td
                      onClick={() => {
                        setSelectedRow(row);
                        setDisplayEditDialog(true);
                      }}
                      className="daily-product-action"
                    >
                      edit
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <IonLoading isOpen={loading} message={"Please wait..."} />
        {/* Inifinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>
      </IonContent>
    </IonModal>
  );
};
