// @ts-nocheck
import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import axios from "axios";

import { APIs } from "../../../services/apiService";
import { RestockColumns } from "../../../constants/restock";
import "./RestockModal.scss";
import { toast } from "react-toastify";

const defaultRestockItem = {
  code: "",
  count: "",
  type: "box",
};

export const RestockModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [restockItems, setRestockItems] = useState([
    {
      ...defaultRestockItem,
    },
  ]);

  const toggleModal = () => {
    props.toggleModal();
  };

  const updateData = (e: any, index: number, key: string) => {
    if (e.target.value)
      setRestockItems((preItems) => {
        let items = [...preItems];
        items[index][key] = e.target.value;
        return items;
      });
  };

  const postRestockProducts = () => {
    setLoading(true);
    try {
      axios
        .post(APIs.returns.index, { ...restockItems[restockItems.length - 1] })
        .then((res) => {
          setLoading(false);
          setRestockItems((preItems) => [
            ...preItems,
            { ...defaultRestockItem },
          ]);

          toast.success("Daily products has been saved");
        });
    } catch (e) {
      setLoading(false);
      // @ts-ignore
      toast.error(e?.customErrorMessage ?? "ERROR getting products");
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Restock Product:</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="text-success font-weight-bold"
              onClick={postRestockProducts}
            >
              Save
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {/* <IonButtons>
          <IonButton
            onClick={() => {
              setDailyItems((items) => [...items, { ...defaultDailyItem }]);
            }}
          >
            Add New Row <IonIcon icon={addCircleOutline} size="large" />
          </IonButton>
        </IonButtons> */}

        <div style={{ overflowX: "auto" }}>
          <table className="product-update-table">
            <thead>
              <tr className="product-update-form-size-title">
                {RestockColumns.map((col) => (
                  <th>
                    <h2>{col.label}</h2>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody
              onKeyDown={(e) => {
                if (e.key === "Enter") postRestockProducts();
              }}
            >
              {restockItems?.map((row: any, index) => (
                <tr key={"row" + index}>
                  {RestockColumns.map((col, i) => {
                    return (
                      <td key={"row" + i + "col" + i}>
                        {col.type === "select" ? (
                          <IonSelect
                            placeholder={RestockColumns[i]?.label}
                            okText="Select"
                            cancelText="Cancel"
                            value={row[col.columnName]}
                            onIonChange={(e) =>
                              updateData(e, i, col.columnName)
                            }
                          >
                            {/* <IonSelectOption value="bag">Bag</IonSelectOption> */}
                            <IonSelectOption value="box">Box</IonSelectOption>
                          </IonSelect>
                        ) : (
                          <IonInput
                            value={row[col.columnName]}
                            onIonChange={(e) =>
                              updateData(e, index, col.columnName)
                            }
                            type={RestockColumns[i]?.type}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"Please wait..."} />
      </IonContent>
    </IonModal>
  );
};
