// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIs } from "../../../../services/apiService";
import "./Shelving.scss";
import { AddCodeModal } from "./AddCodeModal/AddCodeModal";
import { WarehouseButton } from "../../WarehouseButton/WarehouseButton";

export const Shelving = (props: any) => {
  const [displayCodeModal, setDisplayCodeModal] = useState(false);
  const [tasks, setTasks] = useState([]);

  const getTasks = async () => {
    // if (!isInfiniteScroll) setLoading(true);
    return await axios
      .get(APIs["product-tasks"].index, {})
      .then((res) => {
        setTasks(res.data.data);
        console.log(res);
      })
      .catch((err) => {
        // if (!isInfiniteScroll) setLoading(false);

        toast.error(
          err.customErrorMessage ?? "error in getting locations list"
        );
      });
  };

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <>
      <WarehouseButton
        onClickHandler={() => setDisplayCodeModal(true)}
        fill="solid"
        size="small"
        text="Add Code"
      />

      {tasks.length &&
        tasks.map((task) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              backgroundColor: "yellow",
              height: 48,
              padding: "0 8px",
              gap: "16px",
            }}
          >
            <div>{task["product_size"]?.product?.title}</div>
            <div>{task["product_size"].code}</div>
            <div>{new Date(task.created_at).toLocaleDateString()}</div>
          </div>
        ))}

      {displayCodeModal && (
        <AddCodeModal
          displayModal={displayCodeModal}
          toggleModal={() => setDisplayCodeModal(!displayCodeModal)}
        />
      )}
    </>
  );
};
