// @ts-nocheck
import React from "react";

import "./Select.scss";
import { IonSelect, IonSelectOption } from "@ionic/react";
import classNames from "classnames";

export const Select = ({ className = "" }) => {
  return (
    <IonSelect
      className={classNames("ion-select", className)}
      aria-label="search items"
      interface="popover"
      placeholder="Select"
      fill="outline"
    >
      <IonSelectOption value="apples">Aisle</IonSelectOption>
      <IonSelectOption value="oranges">Bay</IonSelectOption>
      <IonSelectOption value="bananas">Level</IonSelectOption>
      <IonSelectOption value="bananas">Position</IonSelectOption>
    </IonSelect>
  );
};
